import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, SplashPage, TwoColumnLayout } from '../../components/Layout'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { Trans } from 'react-i18next'

import ROUTES from '../../config/routes'
import LangPicker from '../../components/LanguagePicker'
import Link from '../../components/Link/GatsbyLink'

import GoBackButton from './_components/GoBackButton'
import Container from './_components/Container'
import Title from './_components/Title'
import Description from './_components/Description'
import SignIn from '../../components/Form/SignIn'

import signInImagePNG from '../../static/eastwood/signin-v2.png'
import useAuth from '../../state/auth/useAuth'

const LoginPage = () => {
  const { t } = useTranslation('pages')

  const { isLoaded, isGuest, reloadData } = useAuth()

  if (isLoaded === false) {
    return <SplashPage />
  }

  if (isGuest === false) {
    return <Redirect to={ROUTES.dashboard} />
  }

  return (
    <TwoColumnLayout
      leftClassnames={'bg-white'}
      rightClassnames={'bg-gray-200'}
      rightChildren={
        <div>
          <Link
            to={ROUTES.signup}
            className={
              'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 z-10'
            }
          >
            {t('signin.signUpLink')}
          </Link>

          <img
            src={signInImagePNG}
            alt={'Sign In - Academator'}
            className={'w-100 absolute bottom-0 scale-x-flip mb-20 pb-2'}
          />

          <LangPicker
            containerClass={'absolute bottom-0 right-0 mb-12 mr-12 text-sm text-gray-700'}
          />
        </div>
      }
    >
      <Helmet>
        <title>{t('signin.seo.title')}</title>
      </Helmet>

      <GoBackButton onClick={() => navigate(ROUTES.goBack)} />

      <Link
        to={ROUTES.signup}
        className={
          'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 lg:hidden'
        }
      >
        {t('signin.signUpLink')}
      </Link>

      <Container>
        <Title>{t('signin.title')}</Title>

        <Description>
          <Trans i18nKey={'signin.subtitle'} t={t}>
            Don&apost have an account?{' '}
            <Link className={'tracking-tight font-medium'} to={ROUTES.signup}>
              {{ signup: t('signin.signUpLink') }}
            </Link>
          </Trans>
        </Description>

        <SignIn onSuccess={() => reloadData()} />
      </Container>
    </TwoColumnLayout>
  )
}

export default LoginPage
