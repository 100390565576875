import React, { useState } from 'react'
import {
  SolidRow,
  SolidError,
  SIZES,
  SolidFloatingLabelInput,
  SolidCheckbox,
  SolidCheckboxLabel,
} from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from '../../Link/GatsbyLink'
import ROUTES from '../../../config/routes'
import PropTypes from 'prop-types'

type SignInType = {
  onSuccess: () => void
}

const SignIn = ({ onSuccess }: SignInType) => {
  const { t } = useTranslation('forms')

  const [isSubmitting, setSubmitting] = useState(false)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postSignIn(email, password, rememberMe)

      setSubmitting(false)
      setEmail('')
      setPassword('')
      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        return error.handle422({
          email: setEmailError,
          password: setPasswordError,
        })
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <SolidRow>
        <SolidFloatingLabelInput
          label={t('signin.email.label')}
          size={SIZES.LARGE}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          isInvalid={emailError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={'email'}
          placeholder={t('signin.email.placeholder')}
        />

        {emailError && <SolidError>{emailError}</SolidError>}
      </SolidRow>

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('signin.password.label')}
          size={SIZES.LARGE}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value)
            setPasswordError('')
          }}
          isInvalid={passwordError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('signin.password.placeholder')}
          rightContent={
            <FontAwesomeIcon
              fixedWidth
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible)
              }}
              className={'text-xl cursor-pointer text-gray-400 mt-1'}
              icon={['fal', isPasswordVisible ? 'eye-slash' : 'eye']}
            />
          }
        />

        {passwordError && <SolidError>{passwordError}</SolidError>}
      </SolidRow>

      <div className={'flex justify-between items-center mb-5'}>
        <label>
          <SolidCheckbox
            checked={rememberMe}
            size={SIZES.LARGE}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(!rememberMe)}
          />

          <SolidCheckboxLabel>{t('signin.rememberMe.label')}</SolidCheckboxLabel>
        </label>

        <Link to={ROUTES.passwordReset} className={'tracking-tight'}>
          {t('signin.forgotPasswordLink')}
        </Link>
      </div>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('signin.submit.label')}
      />
    </form>
  )
}

SignIn.propTypes = {
  onSuccess: PropTypes.func,
}

SignIn.defaultProps = {
  onSuccess: () => null,
}

export default SignIn
